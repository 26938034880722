<template>
  <div class="group-header" :class="{ removeTopMargin }">
    <div class="title" v-if="!hideTitles">{{ title }}</div>
    <hr v-if="!hideTitles" />
    <div
      class="description"
      v-if="formattedDescription != ''"
      v-html="formattedDescription"
    />
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'hideTitles', 'removeTopMargin'],
  computed: {
    formattedDescription() {
      if (!this.description) return ''
      return this.description
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
    },
  },
}
</script>

<style lang="scss">
.group-header {
  margin-top: 40px;

  &.removeTopMargin {
    margin-top: 0;
  }

  > .title {
    font-family: Oswald;
    font-size: 27px;
    font-weight: 100;
    line-height: 5px;
  }
  > hr {
    margin-bottom: 5px;
  }
  > .description {
    color: #6a6a6a;
    margin-bottom: 10px;
  }
}
</style>
