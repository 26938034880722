<template>
  <div v-if="services.length" class="service-carousel">
    <div
      ref="cards"
      class="cards"
      @wheel="onWheel"
      v-touch:swipe.left="scrollNext"
      v-touch:swipe.right="scrollPrev"
      :class="{ centerCard: services.length == 1 }"
    >
      <div
        class="card"
        v-for="service in services"
        :key="service.id"
        @click="click(service)"
      >
        <div
          class="image"
          :style="{ backgroundImage: `url(${service.avatar})` }"
        ></div>
        <div class="info">
          <div class="title">{{ service.title }}</div>
        </div>
      </div>
    </div>

    <div v-if="services.length > 1" class="dots">
      <div
        class="dot"
        v-for="(service, k) in services"
        :key="k"
        :class="{ active: active == k }"
        @click="active = k"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['services'],
  data() {
    return {
      scrollNextInterval: null,
      active: 0,
    }
  },
  mounted() {
    this.resetScrollNextInterval()
  },
  methods: {
    onWheel(e) {
      const oldActive = this.active
      e.deltaY <= 0 ? this.scrollPrev() : this.scrollNext()
      if (oldActive != this.active) e.preventDefault()
    },
    resetScrollNextInterval() {
      if (this.scrollNextInterval) clearInterval(this.scrollNextInterval)
      this.scrollNextInterval = setInterval(() => {
        this.active++
        if (this.active >= this.services.length) this.active = 0
      }, 5000)
    },
    click(category) {
      if (!category.services)
        return this.$router.push(`/service/${category.id}`)
      if (['BOOKING'].includes(category.type))
        return this.$router.push(`/service/${category.id}`)
      if (['MAP'].includes(category.type))
        return this.$router.push(`/category/${category.id}`)

      if (category.type == 'ONCE' || category.services.length == 1) {
        let service = category.services[0]
        this.$router.push(`/service/${service.id}`)
        return
      }
      if (category.stay)
        this.$router.push(`/category/${category.id}/${category.propertyId}`)
      else this.$router.push(`/category/${category.id}`)
    },
    scrollNext() {
      this.active++
      if (this.active >= this.services.length)
        this.active = this.services.length - 1
      this.resetScrollNextInterval()
    },
    scrollPrev() {
      this.active--
      if (this.active < 0) this.active = 0
      this.resetScrollNextInterval()
    },
  },
  watch: {
    active(active) {
      this.resetScrollNextInterval()

      let cards = this.$refs.cards
      window.smoothScroll({
        scrollingElement: cards,
        xPos: cards.children[active].offsetLeft,
      })
      this.$forceUpdate()
    },
  },
  beforeDestroy() {
    clearInterval(this.scrollNextInterval)
  },
}
</script>

<style lang="scss">
.service-carousel {
  width: 100%;
  margin-bottom: 10px;

  .cards {
    width: 100%;
    height: 190px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    touch-action: pan-y;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 10px;

    &.centerCard {
      display: flex;
      justify-content: center;
    }

    .card {
      position: relative;
      display: inline-block;
      overflow: hidden;

      height: 100%;
      width: 100%;
      max-width: 400px;
      border: none;
      border-radius: 16px;
      box-shadow: 0px 5px 7px 0px rgba(50, 50, 50, 0.32);

      margin-right: 15px;
      &:last-of-type {
        margin-right: 0;
      }
      cursor: pointer;

      &:hover {
        .image {
          filter: brightness(0.6);
        }
      }

      .image {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        filter: brightness(0.7);
        transition: 0.2s all;
      }

      .info {
        position: absolute;
        bottom: 10px;
        left: 20px;
        right: 30px;

        .title {
          color: #ffffff;
          font-size: 23px;
          font-weight: 100;
          text-transform: uppercase;
          white-space: pre-wrap;
          font-family: Oswald;
          line-height: 1.1;
        }
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;

    .dot:last-of-type {
      margin-right: 0;
    }

    .dot {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      background-color: #e0e0e0;
      transition: 0.2s all;
      border-radius: 16px;
      cursor: pointer;

      &.active {
        background-color: #575757;
        cursor: default;
      }
    }
  }
}
</style>
