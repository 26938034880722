<template>
  <div class="card-component" :class="{ disabled }">
    <div class="logo" :style="{ backgroundImage: 'url(' + image + ')' }">
      <div class="overlay">
        <span class="subtitle">{{ subtitle }}</span>
        <span class="label">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'subtitle', 'image', 'disabled'],
}
</script>

<style lang="scss">
.card-component {
  box-shadow: 0px 5px 7px 0px rgba(50, 50, 50, 0.32);
  border-radius: 18px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1 / 1;
  transition: 0.25s all;

  &.disabled {
    cursor: default;
    > .logo > .overlay {
      background-color: rgba(255, 255, 255, 0.65);
    }
  }

  &:not(.disabled):hover {
    filter: brightness(0.85);
  }

  > .logo {
    flex: 1;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: inherit;
    position: relative;

    > .overlay {
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      transition: 0.25s all;
      background: linear-gradient(transparent 60%, rgba(0, 0, 0, 1));
      padding: 10px 15px;

      > .subtitle {
        line-height: 1;
        font-size: 10px;
        text-align: center;
        color: white;
        margin-bottom: 5px;
      }

      > .label {
        color: white;
        transition: 0.25s all;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 17px;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
