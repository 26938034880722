<template>
  <div id="excursionsView">
    <TopMenu
      :title="$t('hotel.groups.excursions')"
      :customBack="() => {$router.push('/hotel/' + id)}"
    />
    <ServiceCategories :categories="categories" v-if="categories" :hideTitles="true" />
  </div>
</template>

<script>

import ServiceCategories from '@/components/ServiceCategories.vue'
import TopMenu from '@/components/TopMenu.vue'

export default {
  props: ['id'],
  components: { ServiceCategories, TopMenu },
  data() {
    return {
      categories: null
    }
  },
  mounted() {
    this.$api.get('hotels/' + this.id)
    .then(res => {
      this.categories = res.data.data.serviceCategories.filter(category => category.type == 'EXCURSION')
    })
  },
  computed: {
  }
}
</script>

<style lang="scss">
#excursionsView {
  .service-categories {
    padding: 0 15px;
  }
}
</style>