<template>
  <div id="categories">
    <Card
      v-for="category in categories"
      :key="category.id"
      :label="category.title"
      :image="category.avatar"
      @click.native="click(category)"
    />
  </div>
</template>

<script>
import Card from '@/components/Card'

export default {
  props: ['categories'],
  components: { Card },
  methods: {
    click(category) {
      if (['BOOKING'].includes(category.type))
        this.$router.push(`/service/${category.id}`)
      else {
        if (category.type == 'ONCE' || category.services.length == 1) {
          let service = category.services[0]
          this.$router.push(`/service/${service.id}`)
        } else
          category.stay
            ? this.$router.push(
                `/category/${category.id}/${category.propertyId}`,
              )
            : this.$router.push(`/category/${category.id}`)
      }
    },
  },
}
</script>

<style lang="scss">
#categories {
  max-width: 400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
}
</style>
